<template>
  <div class="add-box">
    <!-- <div class="goback" @click="$router.go(-1)">
            <img src="./img/goback.png" style="width:30px;height:30px">
        </div> -->
    <el-form :model="form" ref="form" label-width="140px" class="form-box">
      <div class="flex">
        <el-form-item
          label="产品类别："
          prop="target"
          :rules="[
            { required: true, message: '请选择产品类别', trigger: ['blur'] },
          ]"
        >
          <el-select
            v-model="form.target"
            style="width: 250px"
            :disabled="isView"
            @change="changeTarget"
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.target"
              :value="item.target"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="二级分类："
          v-if="typesonList && typesonList.length > 0"
          prop="targetName"
          :rules="[
            { required: true, message: '请选择二级分类', trigger: ['blur'] },
          ]"
        >
          <el-select
            v-model="form.targetName"
            style="width: 250px"
            :disabled="isView"
          >
            <el-option
              v-for="(item, index) in typesonList"
              :key="index"
              :label="item.target"
              :value="item.target"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="flex">
        <!-- <el-form-item
          label="空间："
          v-if="placeList && placeList.length > 0"
          prop="placeCode"
        >
          <el-select
            v-model="placeCode"
            style="width: 250px"
            :disabled="isView"
            multiple
          >
            <el-option
              v-for="(item, index) in placeList"
              :key="index"
              :label="item.placeName"
              :value="item.placeCode"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="标签：" prop="lable">
          <el-input
            v-model="form.lable"
            style="width: 250px"
            maxlength="5"
            placeholder="请输入标签（5个字以内）"
            :disabled="isView"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="类型："
          prop="detailsType"
          :rules="[
            { required: true, message: '请输入类型', trigger: ['blur'] },
          ]"
        >
          <el-input
            v-model="form.detailsType"
            style="width: 250px"
            placeholder="请输入"
            :disabled="isView"
          ></el-input>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item
          label="主标题："
          prop="name"
          :rules="[
            { required: true, message: '请输入主标题', trigger: ['blur'] },
          ]"
        >
          <el-input
            v-model="form.name"
            style="width: 250px"
            placeholder="请输入"
            :disabled="isView"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="小标题："
          prop="subtitle"
          :rules="[
            { required: true, message: '请输入小标题', trigger: ['blur'] },
          ]"
        >
          <el-input
            v-model="form.subtitle"
            style="width: 250px"
            placeholder="请输入"
            :disabled="isView"
          ></el-input>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item
          label="封面图："
          prop="productImgUrl"
          :rules="[
            { required: true, message: '请上传封面图', trigger: ['blur'] },
          ]"
        >
          <el-upload
            :disabled="isView"
            class="upload-demo"
            :action="projectName + '/common/backstage/file/fileupload'"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-image
              v-if="form.productImgUrl"
              :src="
                projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(form.productImgUrl)
              "
              class="adv-img"
              :preview-src-list="[
                projectName +
                  '/common/backstage/file/fileDownLoad?filePath=' +
                  encodeURIComponent(form.productImgUrl),
              ]"
            >
            </el-image>
            <!-- <img
              v-if="form.productImgUrl"
              :src="
                projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(form.productImgUrl)
              "
              class="adv-img"
            /> -->
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="商品品牌：" label-width="190px">
          <el-input
            v-model="form.productBrand"
            style="width: 250px"
            placeholder="请输入"
            :disabled="isView"
          ></el-input>
        </el-form-item>
      </div>

      

      <el-form-item
        label="是否为特价产品："
        prop="isSpecial"
        :rules="[
          {
            required: true,
            message: '请选择是否为特价产品',
            trigger: ['blur'],
          },
        ]"
      >
        <el-radio v-model="form.isSpecial" label="1" :disabled="isView"
          >是</el-radio
        >
        <el-radio v-model="form.isSpecial" label="0" :disabled="isView"
          >否</el-radio
        >
      </el-form-item>
      <div class="list-box">
        <el-form-item
          label="规格："
          prop="specification"
          :rules="[
            { required: true, message: '请选择规格', trigger: ['blur'] },
          ]"
        >
          <el-radio
            v-model="form.specification"
            label="有"
            @change="isChange"
            :disabled="isView"
            >有</el-radio
          >
          <el-radio
            v-model="form.specification"
            label="无"
            @change="isChange"
            :disabled="isView"
            >无</el-radio
          >
        </el-form-item>
        <div
          class="img-add"
          v-if="form.specification == '有' && isView != true"
          @click="addClick"
        >
          <img src="./img/add.png" style="width: 30px; height: 30px" />
        </div>
      </div>

      <div
        v-for="(item, index) in form.productDetailsList"
        :key="index"
        class="list-box"
      >
        <div
          class="img-delete"
          v-if="
            form.productDetailsList.length >= 2 && index >= 1 && isView != true
          "
          @click="deleteClick(index)"
        >
          <img src="./img/deletebtn.png" style="width: 30px; height: 30px" />
        </div>
        <el-form-item
          label="规格："
          v-if="form.specification == '有'"
          :prop="'productDetailsList.' + index + '.specification'"
          :rules="[{ required: true, message: '请输入规格' }]"
        >
          <el-input
            v-model="item.specification"
            style="width: 350px"
            placeholder="请输入"
            :disabled="isView"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="价格："
          :prop="'productDetailsList.' + index + '.price'"
          :rules="[
            { required: true, message: '请输入价格' },
            {
              required: true,
              pattern: /^[0-9]+[\.]?[0-9]{0,2}$/,
              message: '请输入数字或保留两位小数',
            },
          ]"
        >
          <el-input
            v-model="item.price"
            style="width: 350px"
            placeholder="请输入"
            :disabled="isView"
          >
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="横线价格："
          :prop="'productDetailsList.' + index + '.discountPrice'"
        >
          <el-input
            v-model="item.discountPrice"
            style="width: 350px"
            placeholder="请输入"
            :disabled="isView"
          >
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item
          label="是否上架："
          :prop="'productDetailsList.' + index + '.productDetailStatus'"
          :rules="[
            { required: true, message: '请选择', trigger: ['blur', 'change'] },
          ]"
        >
          <el-radio
            v-model.number="item.productDetailStatus"
            :label="0"
            @change="isChange"
            :disabled="isView"
            >否</el-radio
          >
          <el-radio
            v-model.number="item.productDetailStatus"
            :label="1"
            @change="isChange"
            :disabled="isView"
            >是</el-radio
          >
        </el-form-item>
        <el-form-item
          label="主图编码："
          v-if="
            (isEdit == 1 && item.mainCartography) ||
            (isView == true && item.mainCartography)
          "
        >
          <el-input
            v-model="item.mainCartography"
            style="width: 350px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="图片："
          :prop="'productDetailsList.' + index + '.mainPicture'"
          :rules="[
            { required: true, message: '请上传图片', trigger: ['blur'] },
          ]"
        >
          <el-upload
            :disabled="isView"
            class="upload-demo"
            :action="projectName + '/common/backstage/file/fileupload'"
            :headers="headers"
            :show-file-list="false"
            :on-success="avatarSuccess"
            :before-upload="beforeUpload(index)">
            <div  @mouseenter="deletePicCliCK(index)" v-if="item.mainPicture">
            <el-image 
          
            :src="
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(item.mainPicture)
            "
            class="adv-img"
    :preview-src-list="[
              projectName +
              '/common/backstage/file/fileDownLoad?filePath=' +
              encodeURIComponent(item.mainPicture)]">
  </el-image>
</div>
            <!-- <img
              @mouseenter="deletePicCliCK(index)"
              v-if="item.mainPicture"
              :src="
                projectName +
                '/common/backstage/file/fileDownLoad?filePath=' +
                encodeURIComponent(item.mainPicture)
              "
              class="adv-img"
            /> -->
            <div v-else @mouseenter="deletePicCliCK(index)" class="big-img">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
        <!--  @click="isShowTable(item.isShow,index)" -->
        <div class="canshu">
          产品参数：
          <span
            class="arrow"
            v-if="item.isShow == false"
            @click="isOpen(index, 1)"
            ><i class="el-icon-arrow-up"></i
          ></span>
          <span class="arrow" v-else @click="isOpen(index, 2)"
            ><i class="el-icon-arrow-down"></i
          ></span>
        </div>
        <!--  v-if="item.isShow" -->
        <div class="flex" v-if="item.isShow">
          <!-- <el-form-item label="适用空间：">
            <el-input
              v-model="item.rangeSpace"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item> -->
          <!-- v-if="placeList && placeList.length > 0" -->
          <el-form-item
            label="适用空间："
          >
            <el-select
              v-model="item.placeCode"
              style="width: 300px"
              :disabled="isView"
              multiple
              @change="placeCodeChange"
            >
              <el-option
                v-for="(item, index) in placeList"
                :key="index"
                :label="item.placeName"
                :value="item.placeCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="主材质：">
            <el-input
              v-model="item.texture"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="颜色：">
            <el-input
              v-model="item.color"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="尺寸：">
            <el-input
              v-model="item.measure"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="出厂是否含光源：">
            <el-radio v-model="item.isExist" label="是" :disabled="isView"
              >是</el-radio
            >
            <el-radio v-model="item.isExist" label="否" :disabled="isView"
              >否</el-radio
            >
          </el-form-item>
          <el-form-item label="是否智能操控：" style="margin-left: 195px">
            <el-radio v-model="item.isSmart" label="是" :disabled="isView"
              >是</el-radio
            >
            <el-radio v-model="item.isSmart" label="否" :disabled="isView"
              >否</el-radio
            >
          </el-form-item>

          <el-form-item label="光源个数：">
            <el-input
              v-model.number="item.lightNumber"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="灯头/光源型号：">
            <el-input
              v-model="item.lightType"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="调光：">
            <el-input
              v-model="item.dimming"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="电压：">
            <el-input
              v-model="item.voltage"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="功率：">
            <el-input
              v-model="item.power"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="开关插座类型：">
            <el-input
              v-model="item.switchType"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="开孔尺寸：">
            <el-input
              v-model="item.holeSize"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="色温：">
            <el-input
              v-model="item.colorTemperature"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="安装方式：">
            <el-input
              v-model="item.installWay"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="照射面积：">
            <el-input
              v-model="item.irradiatedArea"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="保质期：">
            <el-input
              v-model="item.expirationDate"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>

          <el-form-item label="应急时间：">
            <el-input
              v-model="item.crashTime"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="线长：">
            <el-input
              v-model="item.wireLength"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="位数：">
            <el-input
              v-model="item.digit"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="漏电保护类型：">
            <el-input
              v-model="item.leakageProtectionType"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="电压等级：">
            <el-input
              v-model="item.voltageLevel"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="温度范围：">
            <el-input
              v-model="item.temperatureRange"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
          <el-form-item label="防护等级：">
            <el-input
              v-model="item.protectionLevel"
              style="width: 300px"
              placeholder="请输入"
              :disabled="isView"
            ></el-input>
          </el-form-item>
        </div>
        <div
          class="table-line"
          v-if="form.productDetailsList.length >= 2"
        ></div>
      </div>
      <div class="btn-box" v-if="isView != true">
        <el-button @click="goback">取 消</el-button>
        <el-button type="primary" @click="makeSure" v-if="!productId"
          >确 定</el-button
        >
        <el-button type="primary" @click="editSure" v-if="productId"
          >确 定</el-button
        >
      </div>
      <div class="btn-box" v-if="isView == true">
        <el-button @click="goback">返 回</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  productadd,
  productdetail,
  productedit,
  categorylist,
  typeson,
  placeList,
} from "../../api/index";
import { projectName } from "../../util/config";
export default {
  data() {
    return {
      // isShow:false,
      projectName: projectName,
      headers: { sessionId: sessionStorage.getItem("access_token") },
      form: {
        isSpecial: "0",
        productImgUrl: "", //封面图
        targetName: "",
        placeCode: "",
        label: "",
        productDetailsList: [
          {
            name: "",
            price: "",
            discountPrice: "",
            specification: "",
            mainPicture: "",
            productDetailStatus: 0,
            isShow:false,
          },
        ],
      },
      typeList: [
        // {name:'家居灯',value:'0'},
        // {name:'无主灯/智能灯',value:'1'},
        // {name:'商照',value:'2'},
        // {name:'户外',value:'3'},
        // {name:'光源',value:'4'},
        // {name:'开关插座',value:'5'},
      ],
      typesonList: [],
      placeList: [],
      productId: "",
      ind: 0,
      isView: false,
      isEdit: 0,
      current: 1,
      placeCode: [],
    };
  },
  mounted() {
    if (this.$route.query.productId) {
      this.productId = this.$route.query.productId;
      this.isView = this.$route.query.isView;
      this.isEdit = this.$route.query.isEdit;
      this.current = this.$route.query.current;
    }
    this.getTypeData();
  },
  methods: {
    getTypeData() {
      let data = {
        current: 1,
        size: 1000,
        type: 0,
      };
      categorylist(data).then((res) => {
        if (res.code == 1) {
          this.typeList = res.data.records || [];
          if (this.productId) {
            this.getDetails();
          }
        }
      });
    },
    changeTarget(val) {
      console.log(val,"00000000000--------")
      let obj = {};
      obj = this.typeList.find((item) => {
        return item.target === val;
      });
      let id = obj.id;
      console.log(id,"0----1111111")
      typeson({ id: id }).then((res) => {
        if (res.code == 1) {
          // console.log(res.data, "444");
          this.typesonList = res.data || [];
          this.form.targetName = "";
        }
      });
      placeList({ categoryId: id }).then((res) => {
        if (res.code == 1) {
          // console.log(res.data, "444");
          this.placeList = res.data || [];
          this.form.placeCode = "";
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.productImgUrl = res.data.nginxFileReadPath;
    },
    beforeAvatarUpload() {},
    avatarSuccess(res, file) {
      let data = res.data.nginxFileReadPath;
      this.$set(this.form.productDetailsList[this.ind], "mainPicture", data);
      // console.log(this.form.productDetailsList,"this.form.productDetailsList")
    },
    beforeUpload(index) {
      // console.log(index,"1222222")
      if (this.isEdit == 1) {
        this.ind = index;
      }
    },
    deletePicCliCK(index) {
      console.log(index, "123233333333333333333");
      this.ind = index;
    },
    // isShowTable(isShow,num) {
    //     let dataArr = this.form.productDetailsList;
    //     dataArr.forEach((item,index)=>{
    //         if(isShow == true && num==index) {
    //             item.isShow = false
    //         }
    //         if((isShow == false && num==index) || isShow==undefined) {
    //             item.isShow = true
    //         }
    //     })
    //     console.log(dataArr,"dataArr")
    // },
    addClick() {
      console.log(this.form.productDetailsList, "this.form.productDetailsList");
      let data = this.form.productDetailsList;
      for (let i = 0; i < data.length; i++) {
        if (data[i].mainPicture == "" || data[i].mainPicture == null) {
          this.$message.warning("请完善当前信息！");
          return;
        }
      }
      this.form.productDetailsList.push({
        name: "",
        price: "",
        specification: "",
        mainPicture: "",
        isShow: false,
        productDetailStatus: 0,
      });
      let dataArr = [...this.form.productDetailsList];
      this.$set(this.form, "productDetailsList", dataArr);
      this.form = { ...this.form };
      console.log(this.form, "this.form新加");
    },
    isChange() {
      // console.log("333")
      // let data = this.form.productDetailsList;
      // if(this.form.specification == '无') {
      //     if(data.length>=2) {
      //         this.form.productDetailsList = [this.form.productDetailsList[0]]
      //         console.log(this.form.productDetailsList[0])
      //     }
      // }
      // this.form = {...this.form}
    },
    goback() {
      this.$router.push({
        name: "productManagement",
        query: {
          current: this.current,
        },
      });
    },
    placeCodeChange(val) {
      console.log(val,"kkkkkkkkk")
      console.log(this.form.productDetailsList)
      // this.form
      // let dataArr = this.form.productDetailsList
      // dataArr.forEach(item=>{
      //   item.placeCode = item.placeCode?item.placeCode.join(","):''
      // })
    },
    makeSure() {
      
      this.$refs.form.validate((valid) => {
        if (valid) {
          let dataArr = this.form.productDetailsList;
          dataArr.forEach((item) => {
            item.subtitle = this.form.subtitle;
              item.placeCode = item.placeCode?item.placeCode.join(","):''
          });
          let data = {
            ...this.form,
          };
          console.log(data, "data");
          productadd(data).then((res) => {
            if (res.code == 1) {
              this.$message.success("新增成功！");
              this.$router.push({
                name: "productManagement",
                query: {
                  current: this.current,
                },
              });
            }
          });
        }
      });
    },
    editSure() {
      
      this.$refs.form.validate((valid) => {
        if (valid) {
          let dataArr = this.form.productDetailsList;
          dataArr.forEach((item) => {
            item.placeCode = item.placeCode?item.placeCode.join(","):''
          });
          let data = {
            ...this.form,
            productId: this.productId,
          };
          console.log(data, "data");
          productedit(data).then((res) => {
            if (res.code == 1) {
              this.$message.success("编辑成功！");
              this.$router.push({
                name: "productManagement",
                query: {
                  current: this.current,
                },
              });
            }
          });
        }
      });
    },
    // 产品参数模块内容太多默认隐藏 点击显示或者隐藏
    isOpen(index, num) {
      let arrList = this.form.productDetailsList;
      arrList.forEach((item, ind) => {
        if (index == ind && num == 1) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
        this.form.productDetailsList = [...this.form.productDetailsList];
      });
    },
    getDetails() {
      productdetail({ productId: this.productId }).then((res) => {
        if (res.code == 1) {
          this.form = res.data;
          this.placeCode = this.form.placeCode
            ? this.form.placeCode.split(",")
            : [];
          // this.form.productDetailsList = res.data.productDetailsResponseList || [];
          let dataObj = this.form.productDetailsList;
          dataObj.forEach((item) => {
            item.isShow = false;
            item.placeCode = item.placeCode?item.placeCode.split(","):[]
          });
          // console.log(this.form.productDetailsList,"this.form.productDetailsList")
          // console.log(
          //   this.form.target,
          //   this.typeList,
          //   "this.typeListthis.typeList"
          // );
          // 根据一级分类名称匹配一级分类id然后再查所有二级分类
          if (this.form.target && this.typeList.length > 0) {
            let obj = {};
            obj = this.typeList.find((item) => {
              return item.target === this.form.target;
            });
            let id = obj.id;
            typeson({ id: id }).then((res) => {
              if (res.code == 1) {
                this.typesonList = res.data || [];
              }
            });
            placeList({ categoryId: id }).then((res) => {
              if (res.code == 1) {
                console.log(res.data, "444");
                this.placeList = res.data || [];
              }
            });
          }
        }
      });
    },
    deleteClick(index) {
      let dataArr = this.form.productDetailsList;
      if (dataArr.length >= 2) {
        this.form.productDetailsList.splice(index, 1);
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}
.add-box {
  width: 100%;
  padding-top: 50px;
  position: relative;
  .goback {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }
  .form-box {
    width: 900px;
    margin: 0 auto;
  }
  .list-box {
    position: relative;
    .img-add {
      position: absolute;
      right: 370px;
      top: 65px;
      cursor: pointer;
      z-index: 99;
    }
    .img-delete {
      position: absolute;
      right: 370px;
      top: 4px;
      cursor: pointer;
      z-index: 99;
    }
  }
  .canshu {
    font-size: 15px;
    font-weight: 600;
    color: #606266;
    margin: 20px 0 20px 50px;
    cursor: pointer;
    .arrow {
      margin-left: 5px;
    }
  }
  .flex {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
  }
}
.table-line {
  width: 100%;
  height: 1px;
  background: #dcdfe6;
  margin-bottom: 20px;
}
.btn-box {
  text-align: center;
  margin-top: 50px;
}
.upload-demo /deep/.el-upload {
  width: 200px;
  height: 150px;
  border: 1px dashed #e5e5e5;
  .el-icon-plus {
    margin-top: 60px;
    font-size: 40px;
    color: #e5e5e5;
  }

  .adv-img {
    width: 200px;
    height: 150px;
  }
}
.big-img {
  width: 200px;
  height: 150px;
  // background: pink;
  z-index: 999;
}
</style>